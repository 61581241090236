import React,{useState} from "react";

export function Inicio(){
    const[placa,setPlaca]=useState("");
    const[clave,setClave]=useState("");

    const onPlacaChange = e => setPlaca(e.target.value);
    const onClaveChange = e => setClave(e.target.value);

    const handleSubmit = e => {
        e.preventDefault();

        const data = { placa,clave };
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data)
        };
        fetch("http://apilaravel.desarrollo.hagemsa.org/public/api/validar",requestOptions)
        .then(response => response.json())
        .then(res => console.log(res));
    };




    return (
    <div className="Inicio">
        <h3>Inventario</h3>
        <div>
            <form>
                <label>Ingrese la placa: </label><input placeholder="Placa" value={placa} onChange={onPlacaChange} required />
                <label>Ingrese la clave: </label><input placeholder="Clave" value={clave} onChange={onClaveChange} className="input-form" required /> 
                <button type="submit" onClick={handleSubmit}>Validar</button>
            </form>
        </div>
    </div>
    );
}